<template>
  <div class="row">
    <div
      class="col-12 col-xxl-4 col-xl-4 col-lg-6 col-md-6"
      v-for="limit in bacLimits"
      :key="limit.id"
    >
      <BetLimit
        :level="limit.level"
        :limits="limit.limits"
        @refresh="getLimits"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n/index";
import ApiService from "@/core/services/ApiService";
import BetLimit from "@/components/settings/BetLimit.vue";
export default defineComponent({
  name: "baccarat-bet-limit",
  components: {
    BetLimit,
  },
  setup() {
    const { t } = useI18n();
    const bacLimits = ref([
      {
        id: null,
        level: 1,
        limits: [
          { betpos: "Player", min_bet: 0, max_bet: 0, idx: 0 },
          { betpos: "Banker", min_bet: 0, max_bet: 0, idx: 1 },
          { betpos: "Tie", min_bet: 0, max_bet: 0, idx: 2 },
          { betpos: "Pair", min_bet: 0, max_bet: 0, idx: 3 },
        ],
      },
      {
        id: null,
        level: 2,
        limits: [
          { betpos: "Player", min_bet: 0, max_bet: 0, idx: 0 },
          { betpos: "Banker", min_bet: 0, max_bet: 0, idx: 1 },
          { betpos: "Tie", min_bet: 0, max_bet: 0, idx: 2 },
          { betpos: "Pair", min_bet: 0, max_bet: 0, idx: 3 },
        ],
      },
      {
        id: null,
        level: 3,
        limits: [
          { betpos: "Player", min_bet: 0, max_bet: 0, idx: 0 },
          { betpos: "Banker", min_bet: 0, max_bet: 0, idx: 1 },
          { betpos: "Tie", min_bet: 0, max_bet: 0, idx: 2 },
          { betpos: "Pair", min_bet: 0, max_bet: 0, idx: 3 },
        ],
      },
      {
        id: null,
        level: 4,
        limits: [
          { betpos: "Player", min_bet: 0, max_bet: 0, idx: 0 },
          { betpos: "Banker", min_bet: 0, max_bet: 0, idx: 1 },
          { betpos: "Tie", min_bet: 0, max_bet: 0, idx: 2 },
          { betpos: "Pair", min_bet: 0, max_bet: 0, idx: 3 },
        ],
      },
      {
        id: null,
        level: 5,
        limits: [
          { betpos: "Player", min_bet: 0, max_bet: 0, idx: 0 },
          { betpos: "Banker", min_bet: 0, max_bet: 0, idx: 1 },
          { betpos: "Tie", min_bet: 0, max_bet: 0, idx: 2 },
          { betpos: "Pair", min_bet: 0, max_bet: 0, idx: 3 },
        ],
      },
    ]);

    const getLimits = async () => {
      const data = await ApiService.get("/game/settings/bet-limits")
        .then((res) => res.data)
        .catch(() => []);

      data.forEach((lm) => {
        const idx = bacLimits.value.findIndex((e) => e.level === lm.level);

        bacLimits.value[idx].id = lm.id;
        bacLimits.value[idx].limits = [
          {
            betpos: "Player",
            min_bet: Number(lm.player_min),
            max_bet: Number(lm.player_max),
            idx: 0,
          },
          {
            betpos: "Banker",
            min_bet: Number(lm.banker_min),
            max_bet: Number(lm.banker_max),
            idx: 1,
          },
          {
            betpos: "Tie",
            min_bet: Number(lm.tie_min),
            max_bet: Number(lm.tie_max),
            idx: 2,
          },
          {
            betpos: "Pair",
            min_bet: Number(lm.pair_min),
            max_bet: Number(lm.pair_max),
            idx: 3,
          },
        ];
      });
    };

    onBeforeMount(() => getLimits());
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.BetLimitSetting"), []);
    });
    return {
      bacLimits,
      getLimits,
    };
  },
});
</script>
