<template>
  <div class="card card-flush mt-4">
    <div class="card-header">
      <h3 class="card-title">{{ `${$t("Level")} ${level}` }}</h3>
      <div class="card-toolbar">
        <button
          v-if="!isEdit"
          type="button"
          class="btn btn-sm btn-light"
          @click="editLimit"
        >
          <i class="fas fa-edit me-2"></i>{{ $t("Edit") }}
        </button>
        <span v-else>
          <button
            type="button"
            class="btn btn-sm btn-light"
            @click="updateLimit"
          >
            <i class="fas fa-save me-2"></i>{{ $t("Save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            @click="isEdit = false"
          >
            <i class="fas fa-window-close me-2"></i>{{ $t("Cancel") }}
          </button>
        </span>
      </div>
    </div>
    <div class="card-body">
      <Datatable :table-data="tableData" :table-header="tableHeader">
        <!--Betposition -->
        <template v-slot:cell-betpos="{ row: data }">
          <label>{{ $t("BetLimit." + data.betpos) }}</label>
        </template>
        <!-- Minimum Bet -->
        <template v-slot:cell-min_bet="{ row: data }">
          <label v-if="!isEdit"> {{ $n(data.min_bet, "currency") }}</label>
          <input
            v-else
            type="number"
            v-model="limitMin[data.idx]"
            class="form-control form-control-solid w-150px"
            placeholder="1000"
          />
        </template>
        <!-- Maximum Bet -->
        <template v-slot:cell-max_bet="{ row: data }">
          <label v-if="!isEdit"> {{ $n(data.max_bet, "currency") }}</label>
          <input
            v-else
            type="number"
            v-model="limitMax[data.idx]"
            class="form-control form-control-solid w-150px"
            placeholder="1000"
          />
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "bet-limits",
  components: {
    Datatable,
  },
  emits: ["refresh"],
  props: {
    level: {
      type: Number,
      required: true,
    },
    limits: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const tableHeader = ref([
      {
        name: t("BetLimit.Position"),
        key: "betpos",
        customslot: true,
      },
      {
        name: t("BetLimit.Minimum"),
        key: "min_bet",
        customslot: true,
      },
      {
        name: t("BetLimit.Maximum"),
        key: "max_bet",
        customslot: true,
      },
    ]);
    /**
     * Update Chip
     *
     */
    const isEdit = ref(false);
    const limitMin = ref([1000, 1000, 1000, 1000]);
    const limitMax = ref([1000000, 1000000, 1000000, 1000000]);
    const tableData = ref(props.limits);
    tableData.value.forEach((limit, i) => {
      limitMin.value[i] = Number(limit.min_bet);
      limitMax.value[i] = Number(limit.max_bet);
    });
    /**
     * Set Amount
     *
     */
    watch(
      () => props.limits,
      (newVal) => {
        if (newVal) {
          tableData.value.splice(0, tableData.value.length, ...props.limits);
          tableData.value.forEach((limit, i) => {
            limitMin.value[i] = Number(limit.min_bet);
            limitMax.value[i] = Number(limit.max_bet);
          });
        }
      }
    );
    const editLimit = () => {
      isEdit.value = true;
    };

    const updateLimit = async () => {
      isEdit.value = false;

      await ApiService.post("/game/settings/bet-limits", {
        level: props.level,
        betlimits: {
          player_min: Number(limitMin.value[0]),
          player_max: Number(limitMax.value[0]),
          banker_min: Number(limitMin.value[1]),
          banker_max: Number(limitMax.value[1]),
          tie_min: Number(limitMin.value[2]),
          tie_max: Number(limitMax.value[2]),
          pair_min: Number(limitMin.value[3]),
          pair_max: Number(limitMax.value[3]),
        },
      })
        .then(() => {
          Swal.fire(t("Menu.BetLimitSetting"), t("BetLimit.Update"), "success");
          emit("refresh");
        })
        .catch(() => {
          Swal.fire(t("Menu.BetLimitSetting"), t("TryAgain"), "error");
        });
    };

    return {
      tableHeader,
      tableData,
      isEdit,
      limitMin,
      limitMax,
      editLimit,
      updateLimit,
    };
  },
});
</script>
